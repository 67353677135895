import { CARRIER_USER_SERVICE_URL } from '@/common/config'
import { logger } from '@/services/logger/loggers'
import { ICUSUser } from '../types/cus'
import { Cookies } from '../types/types'
import { OrgType } from '@sennder/senn-node-microfrontend-interfaces'
import { getStateCallbacks } from '@/store/getters'

class CarrierUserService {
  private apiCallParams: {
    mode: RequestMode
    cache: RequestCache
    credentials: RequestCredentials
    headers: { [key: string]: string }
    redirect: RequestRedirect
    referrerPolicy: ReferrerPolicy
  } = {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  async getV4Headers() {
    const commonHeaders = await getStateCallbacks().getCommonHeaders()
    if (!commonHeaders) {
      throw new Error('[CUS]: Error getting v4 common headers')
    }
    return {
      ...this.apiCallParams.headers,
      ...(commonHeaders as {
        Authorization: string
        'X-Org-Type': OrgType
        'X-Org-Id': string
      }),
    }
  }

  async changeLanguage(language: string): Promise<void> {
    const url = `${CARRIER_USER_SERVICE_URL}/carrier-portal/api/users/language`

    const response = await fetch(url, {
      method: 'PATCH',
      ...this.apiCallParams,
      headers: await this.getV4Headers(),
      body: JSON.stringify({
        language,
      }),
    })
    if (response.status !== 200) {
      logger.error(
        `Error changing language to: ${language} error: ${response.statusText}`,
        {}
      )
      throw new Error(
        `[CUS]: language response status code: ${response.status}, status text: ${response.statusText}`
      )
    }
  }

  async acceptCookies(cookies: Cookies[]): Promise<void> {
    const url = `${CARRIER_USER_SERVICE_URL}/carrier-portal/api/users/cookies/accept`

    const response = await fetch(url, {
      method: 'PATCH',
      ...this.apiCallParams,
      headers: await this.getV4Headers(),
      body: JSON.stringify({
        cookies,
      }),
    })
    if (response.status !== 200) {
      logger.error(
        `Error accepting the cookies ${cookies} error: ${response.statusText}`,
        {}
      )
      throw new Error(
        `[CUS]: accept cookies response status code: ${response.status}, status text: ${response.statusText}`
      )
    }
  }

  async whoami(): Promise<ICUSUser> {
    const url = `${CARRIER_USER_SERVICE_URL}/carrier-portal/api/users/whoami`
    const response = await fetch(url, {
      method: 'GET',
      ...this.apiCallParams,
      headers: await this.getV4Headers(),
    })
    if (response.status !== 200) {
      logger.error(
        `Error fetching basic data for user: ${response.statusText}`,
        {}
      )
      throw new Error(
        `[CUS]: whoami response status code: ${response.status}, status text: ${response.statusText}`
      )
    }

    const data = await response.json()
    return <ICUSUser>data
  }
}

export default new CarrierUserService()
