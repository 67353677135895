import { watch } from 'vue'
import { RouteMeta, RouteRecordRaw } from 'vue-router'
import { moduleConfiguration, ModuleConfig } from '@/modules-configuration'
import { authRoutes } from '@/modules/auth'
import {
  isAuthenticated,
  isCompanyCarrier,
  isRootAuthenticated,
  Middleware,
  projectMiddlewares,
} from './middlewares/middlewares'
import { registerMiddlewares } from './middlewares/middlewares-registrator'
import { signupRoutes } from '@/modules/signup'
import { analytics, AppAnalyticsProvider } from '@/services/analyticsProvider'
import { createMfWrapper, MicroFrontendLogger } from '@sennder/shell-utilities'
import {
  getFeatureFlagForRoute,
  isModuleVisible,
} from '@/modules/visibility-handlers'
import {
  getStateCallbacks,
  getStateData,
  getStateProviders,
} from '@/store/getters'
import router from '@/router'
import { getFederatedConfig } from '@/modules/federated-module-helper'
import { IOrcasSharedData } from '@sennder/senn-node-microfrontend-interfaces'
import { logger } from '@/services/logger/loggers'
import { getMicrofrontendData } from '@/store'
import { ComponentDefinition } from '@sennder/shell-utilities/dist/functions/createMfWrapper/types'
import { isLocalEnv } from '@/common/config'
import { loggerInstance } from '@/services/logger'

const featureFlagWatcher = async () =>
  watch(
    () => getStateData().featureFlags,
    (featureFlags) => {
      const currentRoute = router.currentRoute.value
      if (!currentRoute) {
        return
      }
      const routeFeatureFlag = getFeatureFlagForRoute(currentRoute)
      if (routeFeatureFlag && !featureFlags[routeFeatureFlag]) {
        router.push({ path: '/' })
      }
    },
    // immediately execute to check feature flag on direct page load
    { immediate: true }
  )

const createComponent = (module: ModuleConfig) =>
  createMfWrapper<IOrcasSharedData>({
    allowEnvironmentOverrides: isLocalEnv(),
    getData: async () => ({
      data: await getMicrofrontendData(module.component),
      callbacks: getStateCallbacks(),
      providers: getStateProviders(),
    }),
    router,
    hooks: {
      failure: logger.error,
    },
    providers: {
      getAnalytics: (ctx) => new AppAnalyticsProvider(ctx),
      getLogger: (ctx) => new MicroFrontendLogger(ctx, () => loggerInstance),
    },
    mf: {
      id: module.component,
      fml: getFederatedConfig(module),
      context: {
        analytics: module.analyticsContext,
        logger: module.logContext,
      },
    },
    watchers: [featureFlagWatcher],
  })

export const addModuleRoute = (
  module: ModuleConfig,
  componentDefinition: Promise<ComponentDefinition>
) => {
  const component = () => componentDefinition

  const middlewares: Middleware[] = module.middlewares
    ? module.middlewares
        .filter(
          (middlewareName) =>
            !!projectMiddlewares[
              middlewareName as keyof typeof projectMiddlewares
            ]
        )
        .map((middlewareName) => {
          return projectMiddlewares[
            middlewareName as keyof typeof projectMiddlewares
          ]
        })
    : []

  router.addRoute({
    path: module.route,
    name: module.name,
    component,
    meta: {
      layout: module.layout,
      middlewares,
      moduleComponentName: module.component,
      analyticsName: module.analyticsContext.module,
    },
    children: [
      {
        path: ':catchAll(.*)',
        name: module.name,
        meta: {
          middlewares,
          moduleComponentName: module.component,
          analyticsName: module.analyticsContext.module,
        },
        component,
      },
    ],
  })
}

export const replaceModule = (from: ModuleConfig, to: ModuleConfig) => {
  // Find the existing index of config with same name but different component (To not re-generate routes if we already have the correct config)
  const existingConfigIdx = moduleConfiguration.findIndex(
    (existing) =>
      existing.name === to.name && existing.component !== to.component
  )

  // Validate module we want to replace exists
  if (existingConfigIdx > -1) {
    // Remove old routes
    router.removeRoute(from.name)
    // Remove old module config and append new one at same index
    moduleConfiguration.splice(existingConfigIdx, 1, to)
    // Add new routes
    addModuleRoute(to, createComponent(to))

    // Force reloading microfrontend if user is on Orders page
    if (router.currentRoute.value.name === to.name) {
      router.push({ name: to.name })
    }
  }
}

export const registerRoutesAndMiddlewares = () => {
  router.addRoute({
    path: '/',
    name: 'Redirect',
    meta: { middlewares: [isRootAuthenticated] },
  } as RouteRecordRaw)

  for (const route of authRoutes) {
    router.addRoute(route)
  }

  for (const route of signupRoutes) {
    router.addRoute(route)
  }

  router.addRoute({
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../NotFound.vue'),
    meta: {
      middlewares: [isAuthenticated, isCompanyCarrier],
    },
  })

  // Component cache to avoid re-generating components with same configuration
  const componentCache = new Map<string, Promise<ComponentDefinition>>()

  moduleConfiguration.forEach((module) => {
    // Unique cache-key for each module that shares configuration
    const cacheKey = [
      module.component,
      module.middlewares,
      module.featureFlag,
    ].join('-')

    let component = componentCache.get(cacheKey)
    if (!component) {
      component = createComponent(module)
      componentCache.set(cacheKey, component)
    }

    addModuleRoute(module, component)
  })

  registerMiddlewares(router)

  const PAGE_VIEW_EXCLUDE_COMPONENTS = ['marketplace', 'profile-mf-component']

  router.beforeResolve((to) => {
    const { name, meta } = to
    setPageTitle(String(name), meta)

    // TODO: remove automatic trackPage() calls when all mFs track their own pages
    const component = meta.moduleComponentName
    if (
      (!component || !PAGE_VIEW_EXCLUDE_COMPONENTS.includes(component)) &&
      meta.analyticsName
    ) {
      analytics.trackPage(meta.analyticsName, {
        module: meta.analyticsName,
      })
    }
  })
}

export const getFirstVisibleModulePath = () => {
  const firstVisibleModule = moduleConfiguration.find((module) =>
    isModuleVisible(module, getStateData())
  )
  return firstVisibleModule?.route || '/not-found'
}

const setPageTitle = (name: string, meta: RouteMeta): void => {
  const pageName = meta.title || name.charAt(0).toUpperCase() + name.slice(1)
  document.title = `${pageName} |  Orcas - sennder`
}
