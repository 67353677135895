import { reactive, watch } from 'vue'

import {
  GetTokenOptions,
  IAction,
  ICommonHeaders,
  IMicrofrontendData,
  IOrcasSharedData,
  IUser,
  IUserCompany,
  IUserContact,
  OrgType,
  SharedDataType,
  Tenant,
} from '@sennder/senn-node-microfrontend-interfaces'
import {
  Carrier,
  GPSIntegration,
} from '@sennder/carrier-profile-public-axios-client'

import mothershipService from '@/services/mothershipService'
import {
  getAuth0Header,
  getAuth0Token,
  parseTenantFromToken,
} from '@/services/tokenManager'
import { getFeatureFlags } from '@sennder/senn-node-feature-flags-frontend'
import { logger } from '@/services/logger/loggers'
import { i18n } from '@/services/i18n'
import { logout } from '@/store/logoutActions'
import {
  getMarketplaceAccess,
  getOnboardingFormAccess,
} from '@/store/featuresHelper'
import errorsHandler from '@/services/errors-handler'
import carrierUserService from '@/services/carrierUserService'
import { carrierProfilePublicService } from '../services/carrierProfilePublicService'
import { mapCUSToMothershipUserData } from '@/helpers/user'
import { gtManager } from '../plugins/gtManager'
import { analytics } from '@/services/analyticsProvider'
import { deserialize } from '@/models/carrier-mapper'
import { ICarrier } from '@/models'
import { notify } from './notify'
import { Optional, Prettify } from '@/types/types'
import { permissionService } from '@/services/permissionService'
import { AUTH0_API_AUDIENCE } from '@/common/config'
import { ICUSUser } from '@/types/cus'
import { sendErrorInMonitor } from '@/services/monitor'
import { getStateData } from '@/store/getters'

export { loadUserData } from './userDataHelper'

const defaultTokenOptions: GetTokenOptions = {
  usePopup: false,
  throwException: false,
}

const getAuthToken = async (options = defaultTokenOptions) => {
  const { usePopup, throwException, ...restOptions } = options
  return getAuth0Token(restOptions, usePopup, throwException)
}

const getAuthHeader = async (options = defaultTokenOptions) => {
  const { usePopup, throwException, ...restOptions } = options
  return getAuth0Header(restOptions, usePopup, throwException)
}

async function getCommonHeaders(): Promise<ICommonHeaders> {
  if (!AUTH0_API_AUDIENCE) {
    throw new Error('AUTH0_API_AUDIENCE is not defined')
  }
  const { user, carrier } = getStateData()
  return {
    Authorization: await getAuth0Header({
      audience: AUTH0_API_AUDIENCE,
    }),
    'X-Org-Type': user ? OrgType.CARRIER : null,
    'X-Org-Id': carrier?.id ?? null,
  }
}

export type OrcasExtendedData = {
  isDenylisted: boolean
  hasOrcasAccess: boolean
  carrier?: ICarrier
  gpsIntegration?: GPSIntegration
}

export type OrcasStoreData = Prettify<
  Optional<
    Omit<IOrcasSharedData<OrcasExtendedData>, 'tenant'>,
    'user' | 'company' | 'contact' | 'cpsCarrier'
  >
>

export const getEmptyData = (): OrcasStoreData => {
  return {
    type: SharedDataType.ORCAS,
    featureFlags: getFeatureFlags() || {},
    language: 'en',
    hasMarketplaceAccess: false,
    isDenylisted: false,
    hasOnboardingFormAccess: false,
    hasOrcasAccess: false,
  }
}

export const store = reactive({
  state: {
    data: getEmptyData(),
    callbacks: {
      getToken: getAuthHeader,
      getAuthToken,
      getAuthHeader,
      syncParentRouter: () => {},
      // TODO: deprecate this callback when all mFs are updated
      segmentTrackEvent: analytics.trackEvent,
      onUnauthorized: async () => {
        return logout()
      },
      onUnhandledError: errorsHandler,
      getCommonHeaders,
      getPermissions: async (actions: IAction[]) =>
        permissionService.getPermissions(actions, await getCommonHeaders()),
    },
    providers: {
      logger,
      notifications: {
        error: (message: string) => notify(message, 'error'),
        success: (message: string) => notify(message, 'success'),
      },
      translations: {
        tc: i18n.global.tc,
        t: i18n.global.t,
      },
      // TODO: deprecate segment provider when all mFs are updated to use analytics provider
      segment: analytics,
      analytics,
      monitoring: {
        sendError: sendErrorInMonitor,
      },
    },
  } satisfies IMicrofrontendData<OrcasStoreData>,
})

// watch feature flags changes in senn-node-feature-flags-frontend and update store
watch(
  getFeatureFlags,
  (featureFlags) => {
    store.state.data.featureFlags = featureFlags
  },
  { deep: true }
)

const loadCusUser = async () => {
  try {
    const user = await carrierUserService.whoami()

    if (!user.carrier_ids[0])
      throw new Error('User is not associated with a carrier')

    return user
  } catch (error: any) {
    logger.error(
      `[CUS Service]: Unable to fetch user data, error: ${error.stack}`,
      { error }
    )
    throw new Error('Error fetching CUS user data')
  }
}

const loadCarrierData = async () => {
  const userCompany = await mothershipService.getCompanyDetails()
  logger.setCarrierId(userCompany.carrierId)

  userCompany.cpsCarrierId =
    await carrierProfilePublicService.exchangeMothershipCarrierIdForNewUUID(
      Number(userCompany.carrierId)
    )

  const cpsCarrier = await carrierProfilePublicService.getCarrier(
    userCompany.cpsCarrierId
  )

  return [userCompany, cpsCarrier] as const
}

export const loadState = async () => {
  let cusUser: ICUSUser
  let userCompany: IUserCompany
  let user: IUser
  let userContact: IUserContact
  let cpsCarrier: Carrier
  try {
    const response = await Promise.all([
      loadCarrierData(),
      loadCusUser(),
      mothershipService.getProfile(),
      mothershipService.getContactDetails(),
    ])
    ;[[userCompany, cpsCarrier], cusUser, user, userContact] = response

    gtManager.pushEvent('orcas_page_load', { user_id: cusUser.id })
    user.language = cusUser.info.language || 'en'
    user.hasAcceptedLatestTerms = cusUser.has_accepted_latest_tnc
    if (getStateData().featureFlags['orcas-shell-fetch-user-data-from-cus']) {
      user = mapCUSToMothershipUserData(cusUser)
    }
  } catch (error) {
    logger.error('[orcas-shell - loadState]: Data can not be loaded', { error })
    notify(`Server does not respond. Please try again later`, 'error')

    await logout()

    return false
  }

  const hasMarketplaceAccess = getMarketplaceAccess(cpsCarrier)
  const hasOnboardingFormAccess = getOnboardingFormAccess(cpsCarrier)

  store.state.data = {
    ...store.state.data,
    type: SharedDataType.ORCAS,
    user: user,
    hasOnboardingFormAccess,
    contact: userContact,
    carrier: deserialize(cpsCarrier), // deprecated, use `cpsCarrier` obj
    cpsCarrier,
    company: userCompany,
    gpsIntegration: cpsCarrier.gps_integration,
    featureFlags: getStateData().featureFlags,
    hasMarketplaceAccess,
    isDenylisted: !!cpsCarrier?.is_denylisted,
    hasOrcasAccess: hasMarketplaceAccess,
  }

  return true
}

export const getMicrofrontendData = async (component: string) => {
  const data: OrcasStoreData & { tenant?: Tenant } = getStateData()

  if (component === 'sennfuel-mf-component') {
    // We need to stop this casting madness
    return data as IOrcasSharedData<OrcasExtendedData>
  }

  if (!data.user) {
    throw new Error('state.data.user is not initialized')
  }
  if (!data.company) {
    throw new Error('state.data.company is not initialized')
  }
  if (!data.contact) {
    throw new Error('state.data.contact is not initialized')
  }
  data.tenant = await parseTenantFromToken()
  return data as IOrcasSharedData<OrcasExtendedData>
}
