// if the host includes .it
const isItaly = window.location.host.includes('.it')

if (isItaly) {
  // Replace href with .com and redirect
  // Note that .replace will only replace the first occurrence so we don't mutate anything else
  const newUrl = window.location.href.replace('.it', '.com')
  window.location.replace(newUrl)
}

import './bootstrap'
