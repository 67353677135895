import { HttpClient } from './http-client/HttpClient'
import httpProvider from './http-provider'
import { getAuth0Header } from '@/services/tokenManager'
import {
  IUser,
  IUserCompany,
  IUserContact,
} from '@sennder/senn-node-microfrontend-interfaces'
import { getMothershipUrl } from '@/common/config'
import { parseTenantFromUrl } from './tenant'

class MothershipService extends HttpClient {
  public async getCompanyDetails(): Promise<IUserCompany> {
    return this.get('/ma-franchise/queries/company-details/')
  }

  public async getProfile(): Promise<IUser> {
    return this.get('/accounts/users/me')
  }

  public async getContactDetails(): Promise<IUserContact> {
    return this.get('/ma-franchise/queries/contact-details/')
  }

  public async sendCookies(cookies: Array<string>): Promise<void> {
    return this.post('/accounts/commands/accept-cookies', { data: cookies })
  }
}

const mothershipUrl = getMothershipUrl(parseTenantFromUrl())
if (!mothershipUrl) {
  throw new Error('mothershipUrl not found')
}

export default new MothershipService({
  httpProvider,
  getAuthHeader: getAuth0Header,
  baseURL: mothershipUrl,
})
