import { loadState } from '@/store'
import { getStateData, getStateUser } from '@/store/getters'
import { identifyUserInLaunchDarkly } from '@/services/launchDarkly'
import { getAuth0Header } from '@/services/tokenManager'
import startAsyncService from '@/services/retry-service'
import { userIdentifiedOnLaunchDarkly } from '@/components/modal-manager'
import { identifyUserInTracking } from '@/services/tracking'
import { syncCookieConsent } from '@/services/cookies'
import { isLocalEnv, isUatEnv } from '@/common/config'
import { analytics } from '@/services/analyticsProvider'
import { parseTenantFromUrl } from '@/services/tenant'
import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'
import { logout } from './logoutActions'
import { translationProvider } from '@/services/translationProvider'

const LAUNCH_DARKLY_SERVICE_NAME = 'Launch Darkly'

export const loadUserData = async (onLogin = false) => {
  const authHeader = await getAuth0Header()
  if (!authHeader) {
    console.warn('[orcas-shell - userDataHelper] - Undefined auth header')
    return
  }

  if (!(await loadState())) {
    return
  }

  startAsyncService(
    LAUNCH_DARKLY_SERVICE_NAME,
    identifyUserInLaunchDarkly
  ).then(async () => {
    userIdentifiedOnLaunchDarkly()

    const isItalianDomain = parseTenantFromUrl() === Tenant.POSTE
    const hasFeatureFlag =
      getStateData().featureFlags['redirect-orcas-it-users-to-orcas-com']

    if (isItalianDomain && hasFeatureFlag) {
      analytics.trackEvent('orcas/system/redirect-italia-com')

      let url = 'https://app.orcas.sennder.com/'
      const redirectTo = window.location.pathname + window.location.search

      if (isLocalEnv()) {
        url = 'https://app.orcas.dev.sennder.com'
      } else if (isUatEnv()) {
        url = 'https://app.orcas.uat.sennder.com'
      }

      await logout({ replaceUrlTo: `${url}${redirectTo}` })
    }
  })

  await Promise.all([
    translationProvider.set(getStateUser().language),
    identifyUserInTracking(onLogin),
  ])

  // sync consent in background
  syncCookieConsent()
}
