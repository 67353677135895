import { IModulesConfig } from '@sennder/senn-node-microfrontend-interfaces'
import { reactive } from 'vue'

export type ModuleConfig = IModulesConfig & {
  isNewFeature?: boolean
}

export const TransportModule = {
  name: 'Orders',
  component: 'transport-mf-component',
  devPort: '9110',
  route: '/orders',
  layout: 'AppLayoutMain',
  middlewares: ['isAuthenticated', 'isCompanyCarrier'],
  sidebarIcon: 'orders-icon.svg',
  analyticsContext: {
    module: 'orders',
    submodule: '',
  },
  logContext: {
    module: 'transport-mf-component',
    codeOwners: 'transport-planning',
  },
} satisfies IModulesConfig

export const OrdersModule = {
  name: 'Orders',
  component: 'orders-mf-component',
  devPort: '9102',
  route: '/orders',
  layout: 'AppLayoutMain',
  middlewares: ['isAuthenticated', 'isCompanyCarrier'],
  sidebarIcon: 'orders-icon.svg',
  analyticsContext: {
    module: 'orders',
    submodule: '',
  },
  logContext: {
    module: 'orders-mf-component',
    codeOwners: 'order-fulfillment',
  },
} satisfies IModulesConfig

export const moduleConfiguration = reactive<ModuleConfig[]>([
  {
    name: 'Marketplace',
    component: 'marketplace',
    devPort: '9104',
    route: '/marketplace',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    sidebarIcon: 'marketplace-icon.svg',
    analyticsContext: {
      module: 'marketplace',
      submodule: '',
    },
    logContext: {
      module: 'marketplace-mf-component',
      codeOwners: 'pod-discovery',
    },
  },
  {
    name: 'Onboarding',
    component: 'onboarding-component',
    devPort: '9106',
    route: '/onboarding',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    sidebarIcon: 'marketplace-icon.svg',
    featureFlag: 'onboarding-form-access',
    analyticsContext: {
      module: 'onboarding',
      submodule: '',
    },
    logContext: {
      module: 'onboarding-component',
      codeOwners: 'onboarding',
    },
  },
  {
    name: 'Assignments',
    component: 'assignments-mf-component',
    devPort: '9108',
    route: '/assignments',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    sidebarIcon: 'assignment-icon.svg',
    featureFlag: 'SHOW_ASSIGNMENTS-TAB',
    analyticsContext: {
      module: 'assignments',
      submodule: '',
    },
    logContext: {
      module: 'assignments-mf-component',
      codeOwners: 'matching',
    },
  },
  {
    name: 'OrderDetails',
    component: 'marketplace',
    devPort: '9104',
    route: '/order-details/:idForStaff',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    analyticsContext: {
      module: 'order-details',
      submodule: '',
    },
    logContext: {
      module: 'marketplace-mf-component',
      codeOwners: 'pod-discovery',
    },
  },
  {
    name: 'TenderDetails',
    component: 'marketplace',
    devPort: '9104',
    route: '/tender-details/:id',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    analyticsContext: {
      module: 'tender-details',
      submodule: '',
    },
    logContext: {
      module: 'marketplace-mf-component',
      codeOwners: 'pod-discovery',
    },
  },
  {
    name: 'Planner',
    component: 'planner-mf-component',
    devPort: '9198',
    route: '/planner',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    sidebarIcon: 'planner-icon.svg',
    featureFlag: 'microfrontend-planner',
    analyticsContext: {
      module: 'planner',
      submodule: '',
    },
    logContext: {
      module: 'planner-mf-component',
      codeOwners: 'planning',
    },
  },
  {
    name: 'Fleet',
    component: 'fleet-mf-component',
    devPort: '9199',
    route: '/fleet',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    sidebarIcon: 'fleet-icon.svg',
    analyticsContext: {
      module: 'fleet',
      submodule: '',
    },
    logContext: {
      module: 'fleet-mf-component',
      codeOwners: 'transport-planning',
    },
  },
  // Remove this after OrdersModule and OrdersReference are removed
  {
    name: 'TransportsCSVDownload',
    component: 'transport-mf-component',
    devPort: '9110',
    route: '/orders/transports-csv-download',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    analyticsContext: {
      module: 'orders',
      submodule: '',
    },
    logContext: {
      module: 'transport-mf-component',
      codeOwners: 'transport-planning',
    },
  },
  OrdersModule,
  {
    name: 'OrdersReference',
    component: 'orders-mf-component',
    devPort: '9102',
    route: '/orders/:refNo',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    featureFlag: 'order-microfrontend',
    analyticsContext: {
      module: 'orders-reference',
      submodule: '',
    },
    logContext: {
      module: 'orders-mf-component',
      codeOwners: 'order-fulfillment',
    },
  },
  {
    name: 'Profile',
    component: 'profile-mf-component',
    devPort: '9105',
    route: '/profile',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    featureFlag: 'microfrontend-profile',
    analyticsContext: {
      module: 'account',
      submodule: '',
    },
    logContext: {
      module: 'profile-mf-component',
      codeOwners: 'onboarding',
    },
  },
  {
    name: 'SennFUEL',
    component: 'sennfuel-mf-component',
    devPort: '8083',
    route: '/sennfuel',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    sidebarIcon: 'sennfuel-icon.svg',
    featureFlag: 'sennfuel-microfrontend',
    isNewFeature: true,
    analyticsContext: {
      module: 'sennfuel',
      submodule: '',
    },
    logContext: {
      module: 'sennfuel-mf-component',
      codeOwners: 'sennfuel',
    },
  },
  {
    name: 'SennFUELLP',
    component: 'sennfuel-mf-component',
    devPort: '8083',
    route: '/sennfuel-lp',
    layout: 'AppLayoutDefault',
    middlewares: [],
    featureFlag: 'lp-sennfuel-microfrontend',
    analyticsContext: {
      module: 'sennfuel-lp',
      submodule: '',
    },
    logContext: {
      module: 'sennfuel-mf-component',
      codeOwners: 'sennfuel',
    },
  },
  {
    name: 'Contracts',
    component: 'chartering',
    devPort: '8084',
    route: '/contracts',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    sidebarIcon: 'chartering-icon.svg',
    featureFlag: 'chartering',
    analyticsContext: {
      module: 'contracts',
      submodule: '',
    },
    logContext: {
      module: 'chartering',
      codeOwners: 'asset-planning',
    },
  },
  {
    name: 'TermsAndConditions',
    component: 'terms',
    devPort: '9107',
    route: '/terms',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    analyticsContext: {
      module: 'terms',
      submodule: '',
    },
    logContext: {
      module: 'terms',
      codeOwners: 'vetting',
    },
  },
  {
    name: 'Payments',
    component: 'payments-overview-mf-component',
    devPort: '9104',
    route: '/payments',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isCompanyCarrier'],
    sidebarIcon: 'payments-overview-icon.svg',
    featureFlag: 'payments-overview-microfrontend',
    analyticsContext: {
      module: 'payments',
      submodule: '',
    },
    logContext: {
      module: 'payments-overview-mf-component',
      codeOwners: 'order-fulfillment',
    },
  },
])
