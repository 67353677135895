import { getStateUser } from '@/store/getters'
import {
  MicrofrontendEvents,
  onAcceptTermsAndConditions,
  subscribe,
} from '@sennder/senn-node-microfrontend-event-bus'
import router from '@/router'
import { logout } from '@/store/logoutActions'

const termsRouterName = 'TermsAndConditions'

const handleTermsResponse = async (payload: onAcceptTermsAndConditions) => {
  if (!payload.accepted) {
    await logout()
    return
  }

  let routePath = '/'
  if (router.currentRoute.value.query.redirectTo) {
    routePath = router.currentRoute.value.query.redirectTo.toString()
  }
  router.push(routePath)
}

export const redirectToTerms = async (): Promise<void> => {
  await router.isReady()

  const currentRoute = router.currentRoute.value
  const routeName = currentRoute.name?.toString() || ''

  if (routeName !== termsRouterName) {
    await router.push({
      name: termsRouterName,
      query: {
        redirectTo: currentRoute.path.toString(),
      },
    })
  }
}

export const userIdentifiedOnLaunchDarkly = () => {
  const user = getStateUser()
  if (!user.hasAcceptedLatestTerms) {
    user.hasAcceptedLatestTerms = false
    redirectToTerms()
  }
}

export const registerModalManager = () => {
  subscribe<onAcceptTermsAndConditions>(
    MicrofrontendEvents.onAcceptTermsAndConditions,
    (payload) => handleTermsResponse(payload)
  )
}
