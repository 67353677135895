import { isNotAuthenticated } from '@/router/middlewares/middlewares'

export default [
  {
    path: '/auth',
    name: 'Auth',
    meta: { middlewares: [isNotAuthenticated] },
    children: [
      {
        path: ':catchAll(.*)',
        name: 'Auth',
        meta: { middlewares: [isNotAuthenticated], analyticsName: 'auth' },
        component: () => import('./AuthMicrofrontend.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: { middlewares: [isNotAuthenticated], analyticsName: 'auth' },
    component: () => import('./AuthMicrofrontend.vue'),
  },
]
