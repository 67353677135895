import { LDContext, identify } from '@sennder/senn-node-feature-flags-frontend'
import { getStateCompany, getStateUser } from '@/store/getters'
import { userIpManager } from '@/plugins/userIp'
import { LD_USER_STATIC_KEY } from '@/common/config'
import { parseTenantFromToken } from './tokenManager'

export const identifyUserInLaunchDarkly = async () => {
  const user = getStateUser()
  const company = getStateCompany()

  const ldContext: LDContext = {
    kind: 'user',
    key: String(user.id),
    email: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    country: user.timezone,
    tenant: await parseTenantFromToken(),
    carrierId: company.carrierId,
    cpsCarrierId: company.cpsCarrierId,
    organisationType: 'carrier',
    organisationId: company.cpsCarrierId,
  }

  await identify(ldContext)
  console.info(
    '[Orcas shell - LaunchDarkly]: User is identified in LaunchDarkly'
  )
}

export const getLaunchDarklyAnonymousContext = async (): Promise<LDContext> => {
  const userIpAddress = await userIpManager.get()
  return {
    key: LD_USER_STATIC_KEY,
    ip: userIpAddress,
  }
}

export const anonymizeUserInLaunchDarkly = async () => {
  await identify(await getLaunchDarklyAnonymousContext())
  console.info(
    '[Orcas shell - LaunchDarkly]: User is anonymized in LaunchDarkly'
  )
}
